/* You can add global styles to this file, and also import other style files */
// show for screen readers only
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.selectr-tag {
  background: var(--primary-color) !important;
}

.table-user {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb {
  margin-bottom: 0 !important;
}

.for-print {
  display: none;
}

.dropdown-menu-arrow {
  &:before {
    background: #ffffff;
    box-shadow: none;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    left: 20px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(12px);
    z-index: -5;
    border-radius: 2px;
  }
}

.dropdown-menu-right {
  &:before {
    right: 20px;
    left: auto;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: transparent !important;
  }
  @page {
    size: A4 landscape !important;
  }
  .table-user {
    max-width: auto !important;
    overflow: auto !important;
    text-overflow: unset !important;
  }
}

/* Custom */

.bg-primary {
  background-color: var(--primary-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.bs-datepicker-predefined-btns button:hover {
  background-color: var(--blue-50) !important;
}

.bs-datepicker-predefined-btns button {
  background-color: var(--primary-500) !important;
}

.p-tree {
  max-height: 50vh;
  overflow: auto;
}

.dropdown-menu.show {
  max-height: 290px !important;
  overflow: auto !important;
}

.p-dropdown {
  width: 100% !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.25rem 0.55rem !important;
  font-size: 0.875rem !important;
}

.hide-toggle > .dropdown-toggle::after {
  display: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

// Custom tooltip for assets report chart
#chartjs-tooltip {
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 4px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
}
.tooltip-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.tooltip-title,
.tooltip-body {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.tooltip-color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 2px;
}
.tooltip-title span,
.tooltip-body span {
  margin-right: 6px;
}

@media (max-width: 768px) {
  .modal-full-screen .modal-dialog {
    width: 100vw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .modal-full-screen .modal-content {
    height: 100%;
    border-radius: 0;
  }

  .modal-body {
    overflow: auto;
  }

  .modal-header {
    z-index: 9999;
    background: white;
  }
}

.hover-text-primary {
  &:hover {
    color: var(--primary-color) !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
  }
}

.cursor-pointer {
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

.p-tab {
  padding: 0px !important;
  border-style: none !important;
}
.p-tablist-tab-list {
  background: transparent !important;
  border-color: transparent !important;
  font-size: 22px;
  gap: 16px;
}
.p-tabpanels {
  background: transparent !important;
  padding: 0 !important;
}
.p-tab-active {
  background: transparent !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.p-tablist-active-bar {
  height: 0px !important;
}

.card-badge {
  position: absolute;
  right: 0;
  top: 20px;
  color: white;
  background: var(--primary-color);
  padding: 0 16px;
  border-radius: 2px 0px 0px 2px;
}

p-sorticon {
  .p-component.p-iconwrapper {
    vertical-align: bottom;
  }
}
