@mixin badge-variant($bg) {
  color: saturate(darken($bg, 10%), 10);
  background-color: lighten($bg, 32%);

  &[href] {
    @include hover-focus {
      color: color-yiq($bg);
      text-decoration: none;
      background-color: darken($bg, 12%);
    }
  }
}

@mixin badge-outline-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  border: 2px solid $color !important;
  border-radius: $dropdown-border-radius;
}
